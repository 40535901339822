<template>
    <admin-dashboard-layout>
        <v-card :loading="loading">
            <v-card-title>
                <v-row>
                    <v-col v-text="pricePlan.name"/>
                    <v-col class="text-right">
                        <v-btn text :to="{name: 'admin.price-plans.index'}">return</v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-form>
                    <v-row>
                        <v-col md="6">
                            <v-text-field label="Name"  v-model="pricePlan.name" :disabled="loading"/>
                        </v-col>
                        <v-col md="2">
                            <v-select
                                label="Interval"
                                v-model="pricePlan.interval"
                                :disabled="loading"
                                :items="paymentIntervals"/>
                        </v-col>
                        <v-col md="2">
                            <v-text-field label="Price" v-model="pricePlan.amount" :disabled="loading || !!pricePlan.id"/>
                        </v-col>
                        <v-col md="2">
                            <v-switch label="Active" v-model="pricePlan.is_active" :disabled="loading"/>
                        </v-col>
                    </v-row>
                    <h3 v-text="'Restrictions'"/>
                    <v-row>
                        <v-col md="4">
                            <v-text-field
                                label="Maximum file size (mb)"
                                v-model="pricePlan.restrictions.maxFileSize"
                                :disabled="loading"
                            />
                        </v-col>
                        <v-col md="4">
                            <v-select
                                label="Priority in the queue"
                                v-model="pricePlan.restrictions.priority"
                                :items="priorities"
                                :disabled="loading"
                            />
                        </v-col>

                        <v-col md="4">
                            <v-text-field
                                label="Number of concurrent conversions"
                                v-model="pricePlan.restrictions.concurrentConversions"
                                :disabled="loading"
                            />
                        </v-col>
                    </v-row>

                    <v-text-field
                        label="Stripe id"
                        v-model="pricePlan.stripe_id"
                        :disabled="loading || !!pricePlan.id"
                    />

                    <v-row>
                        <v-col md="4">
                            <v-select
                                label="Scopes"
                                v-model="pricePlan.scopes"
                                multiple
                                :items="scopes"
                                :disabled="loading"
                            />
                        </v-col>
                    </v-row>

                    <v-btn @click="handleSave" v-text="'Save'"/>
                </v-form>
            </v-card-text>
        </v-card>
    </admin-dashboard-layout>
</template>

<script>
    import PricePlan from "../../../models/PricePlan";
    import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout";
    import { mapMutations } from "vuex";

    export default {
        name: "price-plan-form",
        components: {AdminDashboardLayout},
        data() {
            return {
                loading: false,
                paymentIntervals: [
                    'month',
                    'year'
                ],
                priorities: [
                    {value: 1, text: 'Medium'},
                    {value: 2, text: 'High'},
                    {value: 3, text: 'Higher'},
                    {value: 4, text: 'Highest'},
                ],
                scopes: [
                    'public',
                    'api'
                ],
                pricePlan: {
                    is_active: false,
                    restrictions: {},
                    scopes: ['public']
                }
            }
        },
        mounted() {
            this.fetch();
        },
        methods:{
            ...mapMutations(['showSnackBar']),

            async fetch() {
                this.loading = true;

                try {
                    if (this.$route.params.id) {
                        const pricePlanId = this.$route.params.id
                        this.pricePlan = await PricePlan.find(pricePlanId)
                    }
                } catch (e) {
                    this.showSnackBar({color: 'error', timeout: 3000, text: e.response?.data?.message || 'Error'})
                } finally {
                    this.loading = false;
                }
            },
            async handleSave() {
                this.loading = true;

                try {
                    const pricePlan = new PricePlan(this.pricePlan)

                    this.pricePlan = await pricePlan.save()
                } catch (e) {
                    this.showSnackBar({color: 'error', timeout: 3000, text: e.response?.data?.message || 'Error'})
                } finally {
                    this.loading = false;
                }
            },
        },
    }
</script>

<style scoped>

</style>
